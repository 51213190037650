import { useCallback } from 'react';

import { Grid, Paper } from '@mui/material';

import { LoadingOverlay } from '@ecp/features/servicing/shared/components';
import { useAccountStatus, useUserForPolicy } from '@ecp/features/servicing/shared/state';

import { AccountOverviewAccordion } from '../AccountOverviewAccordion';
import { AccountStatus } from '../AccountPolicyStatus/AccountStatus';
import { useStyles } from './AccountDetails.styles';
import { EmailSection } from './EmailSection';

export interface AccountDetailsProps {
  policyNumber: string;
  oktaLoginId?: string;
  onSuccess?(value: string): void;
  onError?(value: string): void;
  onSuccessfulEmailUpdate?(): void;
  userId?: string;
}

export const AccountDetails: React.FC<AccountDetailsProps> = ({
  policyNumber,
  oktaLoginId,
  onSuccess,
  onSuccessfulEmailUpdate,
  onError,
}) => {
  const { classes } = useStyles();
  const { user, isLoading: isUserLoading } = useUserForPolicy({ policyNumber, oktaLoginId });
  const { enrollmentStatus, partnerId, userId } = user ?? {};
  const {
    accountStatus,
    isLoading: isAccountStatusLoading,
    refetch,
  } = useAccountStatus({ userId, partnerId });

  const isLoading = isUserLoading || isAccountStatusLoading;

  const onSuccessUnlockHandler = useCallback(async () => {
    refetch();
    onSuccess?.('This account has been unlocked');
  }, [refetch, onSuccess]);

  return (
    <AccountOverviewAccordion title='Account details' defaultExpanded>
      <Grid container spacing={5}>
        <Grid item xs={12} md={6}>
          <Paper elevation={0} variant='outlined' className={classes.accountStatusCard}>
            <EmailSection
              policyNumber={policyNumber}
              oktaLoginId={oktaLoginId}
              partnerId={partnerId}
              userId={userId}
              onSuccess={onSuccess}
              onSuccessfulEmailUpdate={onSuccessfulEmailUpdate}
              onError={onError}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          {!isLoading && user && accountStatus ? (
            <AccountStatus
              subTitle='Online Enrollment'
              accountStatus={accountStatus}
              userId={userId}
              partnerId={partnerId}
              enrollmentStatus={enrollmentStatus ? 'Enrolled' : 'Not Enrolled'}
              onSuccess={onSuccessUnlockHandler}
              onError={onError}
            />
          ) : (
            <LoadingOverlay />
          )}
        </Grid>
      </Grid>
    </AccountOverviewAccordion>
  );
};
