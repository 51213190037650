import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';

import { msalConfig } from '../authConfig';
export const msalInstance = new PublicClientApplication(msalConfig);

export const ReactMsalProvider: React.FC<React.PropsWithChildren> = (props) => {
  const { children } = props;

  return <MsalProvider instance={msalInstance}>{children}</MsalProvider>;
};
