import { Stack } from '@mui/material';

export const OopsMessage: React.FC = () => {
  return (
    <Stack alignItems='center' spacing={1} m={4}>
      <h2>Oops!</h2>
      <p>
        We couldn’t find any results that match your search. Please check your search criteria and
        try again.
      </p>
      <p>
        If this result is not expected: close this window, relaunch the application and try again.
      </p>
    </Stack>
  );
};
