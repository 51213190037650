import { useCallback } from 'react';

import { useLocation, useSearchParams } from 'react-router-dom';

import { Form, useForm } from '@ecp/utils/form';

import { QUERY_PARAMS, usePageFlow } from '@ecp/features/servicing/shared/routing';
import type { UserSearchType } from '@ecp/features/servicing/shared/state';
import { useSharedState, useUserSearch } from '@ecp/features/servicing/shared/state';
import { encodeUrlValue, useDecodedParams } from '@ecp/features/servicing/shared/util';

import { CustomerSearch } from './CustomerSearch';
import { customerSearchFormSchema } from './CustomerSearch/CustomerSearchForm/CustomerSearchForm.schema';
import { ResetSignOutHeader } from './ResetSignOutHeader';

export const AgentHeader: React.FC = () => {
  const { navigate } = usePageFlow();
  const [, setMultipleSearchError] = useSharedState<boolean>('multipleSearchError');
  const [, setSearchParams] = useSearchParams();
  const { searchFor, type } = useDecodedParams();
  const searchType = type as UserSearchType;
  const formContext = useForm({ validations: customerSearchFormSchema });
  const { handleSubmit, reset } = formContext;
  const { clearCache, isFetching } = useUserSearch(searchFor, searchType);
  const location = useLocation();

  const handleClickResetNextCall = useCallback(() => {
    reset({ policy: '', email: '' });
    navigate('/');
    setMultipleSearchError(false);
  }, [navigate, reset, setMultipleSearchError]);

  const onSubmit = useCallback(() => {
    handleSubmit(async (data) => {
      const searchType: UserSearchType = data.policy ? 'policy' : 'email';
      const value = data[searchType] ?? '';

      // Auto Policy must be uppercase once submitted.
      if (searchType === 'policy') {
        data.policy = data.policy?.toUpperCase();
      }
      if (value) {
        const multipleSearchError = !!(data.email && data.policy);
        clearCache();
        // workaround since clearCache seems to not clear synchronously
        // using setTimeout to ensure cache is cleared before setting customer data
        setTimeout(() => {
          setMultipleSearchError(multipleSearchError);
        });

        if (!multipleSearchError) {
          if (location.pathname !== '/') {
            navigate({
              pathname: '/',
              search: `${QUERY_PARAMS.SEARCH_FOR}=${encodeUrlValue(value)}&${
                QUERY_PARAMS.TYPE
              }=${searchType}`,
            });
          } else {
            setSearchParams(
              data[searchType] &&
                searchType && {
                  [QUERY_PARAMS.SEARCH_FOR]: encodeUrlValue(value),
                  type: searchType,
                },
            );
          }
        } else {
          navigate('/');
        }
      }
    })();
  }, [
    clearCache,
    handleSubmit,
    setMultipleSearchError,
    setSearchParams,
    location.pathname,
    navigate,
  ]);

  return (
    <Form
      id='customerSearchForm'
      formProviderProps={formContext}
      onSubmit={onSubmit}
      showBackdrop={isFetching}
    >
      <ResetSignOutHeader resetNextCallControlFunc={handleClickResetNextCall} />
      <CustomerSearch />
    </Form>
  );
};
