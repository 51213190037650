import { useCallback } from 'react';

import { Box, Stack } from '@mui/material';
import { useSearchParams } from 'react-router-dom';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';
import { maskEmail } from '@ecp/utils/common';
import { Form, useField, useForm } from '@ecp/utils/form';

import { TextField } from '@ecp/components';
import { LoadingButton } from '@ecp/features/servicing/shared/components';
import { QUERY_PARAMS } from '@ecp/features/servicing/shared/routing';
import type { UpdateEmailRequest } from '@ecp/features/servicing/shared/state';
import { useUpdateEmail } from '@ecp/features/servicing/shared/state';
import { encodeUrlValue } from '@ecp/features/servicing/shared/util';

import { type EmailSectionFormInputs, emailSectionSchema } from './Email.schema';

export interface EmailSectionProps {
  policyNumber?: string;
  oktaLoginId?: string;
  partnerId?: string;
  userId?: string;
  onSuccess?(value: string): void;
  onError?(value: string): void;
  onSuccessfulEmailUpdate?(): void;
}

export const EmailSection: React.FC<EmailSectionProps> = (props) => {
  const [searchParams] = useSearchParams();
  const searchFor = searchParams.get(QUERY_PARAMS.SEARCH_FOR);
  const type = searchParams.get(QUERY_PARAMS.TYPE);
  const [, setSearchParams] = useSearchParams();

  const {
    policyNumber,
    oktaLoginId,
    onError,
    onSuccess,
    onSuccessfulEmailUpdate,
    partnerId,
    userId,
  } = props;
  const { updateEmail } = useUpdateEmail();

  const formContext = useForm({ validations: emailSectionSchema });
  const { handleSubmit, setValue } = formContext;

  const emailField = useField({
    name: 'email',
    formContext,
    defaultValue: oktaLoginId,
  });

  const onSubmit = useCallback(() => {
    handleSubmit(async (data) => {
      if (data && userId && partnerId) {
        const { email } = data as Required<EmailSectionFormInputs>;
        const updateEmailRequest: UpdateEmailRequest = {
          email: email,
          partnerId: partnerId,
          userId: userId,
        };
        const { error, success } = await updateEmail(updateEmailRequest);
        if (error) {
          if (error === 'Invalid Request') {
            setValue('email', '');
            onError?.(`Invalid email entered. ${maskEmail(email)} is already in use.`);
          } else
            onError?.(
              'We apologize, but we encountered an error while updating the user ID. Please try again now, or wait a few minutes and try again later.',
            );
        } else if (success) {
          onSuccess?.('Changes successfully saved');
          onSuccessfulEmailUpdate?.();
          // We need to reset the oktaLogin in the url since it was updated.
          setSearchParams({
            [QUERY_PARAMS.POLICY_NUMBER]: encodeUrlValue(policyNumber ?? ''),
            [QUERY_PARAMS.SEARCH_FOR]: searchFor ?? '',
            [QUERY_PARAMS.TYPE]: type ?? '',
            [QUERY_PARAMS.OKTA_LOGIN]: encodeUrlValue(email ?? ''),
          });
        }
      }
    })();
  }, [
    handleSubmit,
    partnerId,
    userId,
    updateEmail,
    onError,
    onSuccess,
    onSuccessfulEmailUpdate,
    setSearchParams,
    policyNumber,
    searchFor,
    type,
    setValue,
  ]);

  return (
    <Form id='emailUpdateForm' formProviderProps={formContext} onSubmit={onSubmit}>
      <Stack spacing={5} p={3}>
        <Box mb={2}>
          <TextField
            {...emailField}
            id='email'
            label='Email/user ID'
            fullWidth
            trackingName='email_userid'
            disabled={!oktaLoginId}
            trackingLabel={GoogleAnalyticsLabels.REDACTED}
          />
        </Box>
        {!!oktaLoginId && (
          <Box display='flex' justifyContent='flex-end'>
            <LoadingButton
              variant='primary'
              type='submit'
              trackingName='save_changes_button'
              trackingLabel='save_changes_continue'
            >
              SAVE CHANGES
            </LoadingButton>
          </Box>
        )}
      </Stack>
    </Form>
  );
};
