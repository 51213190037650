import * as yup from 'yup';

import { getPolicyTypeByNumber } from '@ecp/features/servicing/shared/state';

export interface PolicyNumberInputs {
  policyNumber?: string;
}

export const getBasePolicyNumberSchema = (): yup.StringSchema =>
  yup
    .string()
    .required('Policy number is required')
    .test('invalidPolicyNumber', 'Invalid policy number', (value) =>
      value ? getPolicyTypeByNumber(value) !== 'INVALID' : true,
    );
