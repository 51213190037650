import { useCallback, useEffect, useState } from 'react';

import { Box, Stack } from '@mui/material';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';
import { Form, useField, useForm } from '@ecp/utils/form';

import type { AlertType } from '@ecp/components';
import { Alert } from '@ecp/components';
import { LoadingButton, PolicyNumberField } from '@ecp/features/servicing/shared/components';
import { useAgentAssociatedPolicy, useUserForPolicy } from '@ecp/features/servicing/shared/state';

import { AccountOverviewAccordion } from '../AccountOverviewAccordion';
import { OopsMessage } from '../OopsMessage';
import { PoliciesSearchResults } from '../PoliciesSearchResults';
import { WelcomeBack } from '../WelcomeBack';
import type { LinkPolicyToAccountInput } from './LinkPolicyToAccount.schema';
import { linkPolicyToAccountSchema } from './LinkPolicyToAccount.schema';
import { useStyles } from './LinkPolicyToAccount.styles';

interface LinkPolicyToAccountProps {
  partnerId?: string;
  policyNumber: string;
  oktaLoginId?: string;
}

export const LinkPolicyToAccount: React.FC<LinkPolicyToAccountProps> = (props) => {
  const { partnerId, policyNumber, oktaLoginId } = props;
  const { classes } = useStyles();
  const formContext = useForm({
    validations: linkPolicyToAccountSchema,
  });
  const { handleSubmit } = formContext;
  const policyNumberField = useField({ name: 'policyNumber', formContext });
  const [policyNumberSearch, setPolicyNumberSearch] = useState<string | undefined>();
  const [showAlert, setShowAlert] = useState(false);
  const [showAlertType, setShowAlertType] = useState<AlertType | undefined>(undefined);
  const [alertMessage, setAlertMessage] = useState('');
  const [hideResults, setHideResults] = useState(false);

  const handleCloseAlert = useCallback<
    NonNullable<React.ComponentProps<typeof Alert>['onClose']>
  >(() => {
    setShowAlert(false);
  }, []);
  const { isFetching, response: policySearch } = useAgentAssociatedPolicy(policyNumberSearch);
  const { user, refetch } = useUserForPolicy({ policyNumber, oktaLoginId });
  const { userId } = user ?? {};

  useEffect(() => {
    if (policyNumberSearch) setHideResults(false);
  }, [setHideResults, policyNumberSearch, isFetching]);

  const onSubmit = useCallback(() => {
    handleSubmit(async (data) => {
      const { policyNumber: pnSearch } = data as Required<LinkPolicyToAccountInput>;
      setPolicyNumberSearch(pnSearch ? pnSearch.toUpperCase() : undefined);
      setShowAlert(false);
      setHideResults(false);
    })();
  }, [handleSubmit, setPolicyNumberSearch]);

  const handleSuccess = useCallback(
    (value: string) => {
      if (value) {
        setShowAlert(true);
        setAlertMessage(value);
        setShowAlertType('success');
        refetch();
        setHideResults(true);
        policyNumberField.value = '';
      }
    },
    [setShowAlert, setAlertMessage, refetch, setHideResults, policyNumberField],
  );

  const handleError = useCallback(
    (value: string) => {
      if (value) {
        setShowAlert(true);
        setAlertMessage(value);
        setShowAlertType('error');
      }
    },
    [setShowAlert, setAlertMessage],
  );

  const linkPolicySection = (
    <>
      {!!policyNumberSearch && !hideResults && !isFetching ? (
        policySearch?.policy ? (
          <PoliciesSearchResults
            partnerId={partnerId}
            policiesSearch={policySearch}
            userId={userId}
            onSuccess={handleSuccess}
            onError={handleError}
          />
        ) : (
          <OopsMessage />
        )
      ) : (
        <WelcomeBack variant='linkPolicy' />
      )}
    </>
  );

  return (
    <AccountOverviewAccordion title='Link policy to account' defaultExpanded>
      <Stack spacing={3}>
        <Box>
          <Form
            onSubmit={onSubmit}
            formProviderProps={formContext}
            name='linkPolicyForm'
            showBackdrop={isFetching}
          >
            <Stack
              spacing={2}
              direction='row'
              alignItems='flex-end'
              className={classes.searchWrapper}
            >
              <Box>
                <PolicyNumberField
                  {...policyNumberField}
                  className={classes.input}
                  label='Policy Number'
                  ariaLabel='Policy number'
                  variant='outlined'
                  type='search'
                  trackingName='policy_number'
                  trackingLabel={GoogleAnalyticsLabels.REDACTED}
                />
              </Box>
              <Box>
                <LoadingButton
                  isProcessing={isFetching}
                  type='submit'
                  className={classes.searchButton}
                  trackingName='Link_policy_search_button'
                  trackingLabel='link_policy_search_continue'
                >
                  Search
                </LoadingButton>
              </Box>
            </Stack>
          </Form>
        </Box>
        {showAlert && (
          <Alert type={showAlertType} withIcon withAction onClose={handleCloseAlert}>
            {alertMessage}
          </Alert>
        )}
        {linkPolicySection}
      </Stack>
    </AccountOverviewAccordion>
  );
};
