import { StrictMode } from 'react';

import { CssBaseline } from '@mui/material';
import { createRoot } from 'react-dom/client';

import { ReactQueryProvider } from '@ecp/features/servicing/shared/state';
import { theme, ThemeProvider } from '@ecp/themes/base';

import { App } from './app';
import { initializeGlobal } from './initialize';

initializeGlobal().then(() => {
  const container = document.getElementById('root');
  const root = createRoot(container as HTMLElement);
  root.render(
    <StrictMode>
      <ThemeProvider theme={theme}>
        <ReactQueryProvider>
          <CssBaseline />
          <App />
        </ReactQueryProvider>
      </ThemeProvider>
    </StrictMode>,
  );
});
