import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'LinkPolicyToAccount' })((theme) => ({
  input: {
    minWidth: 360,
    '& .MuiInputLabel-formControl': {
      color: theme.palette.common.white,
    },
    '& .MuiFormHelperText-root.Mui-error': {
      '&:before': {
        backgroundColor: theme.palette.common.white,
      },
      color: theme.palette.common.white,
    },
  },
  searchButton: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.main,
    borderRadius: 25,
    padding: `${theme.spacing(2)} ${theme.spacing(3.75)}`,
    '&:hover': {
      backgroundColor: theme.palette.grey[300],
    },
  },
  searchWrapper: {
    backgroundColor: theme.palette.primary.main,
    padding: '13px 20px 24px',
  },
}));
