import { useCallback } from 'react';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { includes } from '@ecp/utils/common';

import type { UiPolicy } from '@ecp/features/servicing/shared/types';
import type { ErrorCode } from '@ecp/types';

import { QUERY_KEYS } from '../constants';
import type {
  ApiRequestOptions,
  ResponseObject,
  ServicingRequestError,
  ServicingResponse,
} from '../servicingRequest';
import { getUiPolicy } from '../util/getUiPolicy';
import {
  getAssociatedPolicy,
  getPolicyForUserSearch,
  postAccountStatus,
  postDelinkPolicy,
  postLinkPolicy,
  postUnlockAccount,
  updateEmail,
} from './api';
import type {
  AccountStatusRequest,
  AccountStatusType,
  DelinkPolicyError,
  DelinkPolicyResponse,
  DelinkPolicySuccess,
  GetAccountStatusResponse,
  GetAssociatedPolicyResponse,
  GetPoliciesForUserSearchResponse,
  GetPolicyUserSearchRequest,
  LinkPolicyError,
  LinkPolicyResponse,
  LinkPolicySuccess,
  PostDelinkPolicyRequest,
  PostLinkPolicyRequest,
  PostUnlockAccountRequest,
  UiPolicyUserSearchResult,
  UnlockAccountError,
  UnlockAccountResponse,
  UnlockAccountSuccess,
  UpdateEmailError,
  UpdateEmailRequest,
  UpdateEmailResponse,
  UpdateEmailSuccess,
  UserSearchForPolicyResponse,
  UserSearchType,
} from './types';

const getAgentUserSearchKey = (request: GetPolicyUserSearchRequest): Array<string | undefined> => [
  QUERY_KEYS.AGENT_SUPPORT_USER_SEARCH,
  request.type,
  request.value,
];

const getUserSearchResponse =
  (request: GetPolicyUserSearchRequest, apiRequestOptions?: ApiRequestOptions) =>
  async (): Promise<UiPolicyUserSearchResult[]> => {
    return getPolicyForUserSearch(request, apiRequestOptions)
      .then((response) => {
        // This covers the case where the email being searched for is unknown
        // Instead of returning an array with 1 object that has undefined policies, return an empty array
        if (response.payload.length === 1 && !response.payload[0].policies) return [];

        return response.payload.map((resp) => ({
          ...resp,
          policies: resp.policies.map(getUiPolicy),
        }));
      })
      .catch((error: ServicingRequestError): UiPolicyUserSearchResult[] | never => {
        if (
          includes(apiRequestOptions?.dontLogErrorsForStatus ?? [], error.errorStack?.status.code)
        ) {
          return [];
        } else throw error;
      });
  };

const usePolicyForUserSearch = (
  value?: string,
  type?: UserSearchType,
  apiRequestOptions?: ApiRequestOptions,
): GetPoliciesForUserSearchResponse => {
  const queryClient = useQueryClient();
  const request: Parameters<typeof getUserSearchResponse>[0] = {
    value,
    type,
  };
  const queryKey = getAgentUserSearchKey(request);
  const { data, isError, isFetching, isLoading, refetch } = useQuery({
    queryKey,
    queryFn: getUserSearchResponse(request, apiRequestOptions),
    enabled: !!value && !!type,
    gcTime: 0,
  });

  const clearCache = useCallback(
    (): void => queryClient.removeQueries({ queryKey }),
    [queryClient, queryKey],
  );

  return {
    results: data,
    isLoading,
    isFetching,
    isError,
    refetch,
    clearCache,
  };
};

/**
 * Used when we are doing a search - not lookup (i.e. user has entered policy num or email to find user associated)
 * Main thing here is we expect 404 from api, so we don't log to datadog
 */
export const useUserSearch = (
  value?: string,
  type?: UserSearchType,
): GetPoliciesForUserSearchResponse =>
  usePolicyForUserSearch(value, type, { dontLogErrorsForStatus: [403, 404] });

/**
 * Used when we are doing a lookup - not search (i.e. user has selected a user/policy),
 * so we return single result matching policy number and oktaLoginId
 */
export const useUserForPolicy = ({
  policyNumber,
  oktaLoginId,
}: {
  policyNumber: string;
  oktaLoginId?: string;
}): UserSearchForPolicyResponse => {
  const userSearchResponse = usePolicyForUserSearch(policyNumber, 'policy');

  return {
    ...userSearchResponse,
    user: !oktaLoginId
      ? userSearchResponse.results?.[0]
      : userSearchResponse.results?.find((result) => result.loginId === oktaLoginId),
  };
};

const getAssociatedPolicyKey = (policyNumber?: string): [string, string] => [
  QUERY_KEYS.AGENT_SUPPORT_ASSOCIATED_POLICY,
  policyNumber ?? '',
];

export const useAgentAssociatedPolicy = (policyNumber?: string): GetAssociatedPolicyResponse => {
  const { data, isError, isFetching, isLoading } = useQuery({
    queryKey: getAssociatedPolicyKey(policyNumber),
    queryFn: async () => {
      let data = {} as UiPolicy;

      if (policyNumber) {
        const expectedStatuses = [403, 404] as const;
        await getAssociatedPolicy(policyNumber, {
          dontLogErrorsForStatus: expectedStatuses,
        })
          .then((res) => (data = getUiPolicy(res.payload)))
          .catch((error: ServicingRequestError) => {
            const errorStatus = error.response?.status as (typeof expectedStatuses)[number];
            if (errorStatus === 403 || errorStatus === 404) {
              return;
            } else {
              throw error;
            }
          });
      }

      return data;
    },
    enabled: !!policyNumber,
    gcTime: 0,
  });

  return {
    response: data,
    isLoading,
    isFetching,
    isError,
  };
};

export const useAgentDelinkPolicy = (): DelinkPolicyResponse<
  PostDelinkPolicyRequest,
  ResponseObject<ServicingResponse<DelinkPolicySuccess>, DelinkPolicyError>
> => {
  const {
    mutateAsync,
    isPending: isSubmitting,
    isError,
  } = useMutation({
    mutationFn: async (props: PostDelinkPolicyRequest) => {
      const { policyNumber, userId, partnerId } = props;
      let delinkPolicyError: DelinkPolicyError | undefined;
      let success: ServicingResponse<DelinkPolicySuccess> | undefined;
      const expectedStatuses = [403] as const;
      await postDelinkPolicy(policyNumber, userId, partnerId, {
        dontLogErrorsForStatus: expectedStatuses,
      })
        .then((res) => (success = res))
        .catch((error: ServicingRequestError) => {
          const errorStatus = error.response?.status as (typeof expectedStatuses)[number];
          if (errorStatus === 403) {
            delinkPolicyError = 'Policy cannot be delinked';
          } else {
            delinkPolicyError = 'Unknown error';
          }
          success = undefined;
        });

      const response: ResponseObject<ServicingResponse<DelinkPolicySuccess>, DelinkPolicyError> = {
        success,
        error: delinkPolicyError,
      };

      return response;
    },
  });

  return {
    delinkPolicy: mutateAsync,
    isLoading: false,
    isError,
    isSubmitting,
  };
};

export const useAgentLinkPolicy = (): LinkPolicyResponse<
  PostLinkPolicyRequest,
  ResponseObject<ServicingResponse<LinkPolicySuccess>, LinkPolicyError>
> => {
  const {
    mutateAsync,
    isPending: isSubmitting,
    isError,
  } = useMutation({
    mutationFn: async (props: PostLinkPolicyRequest) => {
      const { policyNumber, userId, dateOfBirth, partnerId } = props;
      let linkPolicyError: LinkPolicyError | undefined;
      let success: ServicingResponse<LinkPolicySuccess> | undefined;
      const expectedCodes = [403011, 403012] as const;
      await postLinkPolicy(
        {
          policyNumber,
          userId,
          dateOfBirth,
          partnerId,
        },
        { dontLogErrorsForErrorCode: expectedCodes },
      )
        .then((res) => (success = res))
        .catch((error: ServicingRequestError) => {
          const errorCode = error.errorStack?.status.messages[0]
            .code as (typeof expectedCodes)[number];
          switch (errorCode) {
            case 403011:
              linkPolicyError = 'Invalid DOB';
              break;
            case 403012:
              linkPolicyError = 'Policy Already linked';
              break;
            default:
              linkPolicyError = 'Unknown error';
          }
          success = undefined;
        });

      const response: ResponseObject<ServicingResponse<LinkPolicySuccess>, LinkPolicyError> = {
        success,
        error: linkPolicyError,
      };

      return response;
    },
  });

  return {
    linkPolicy: mutateAsync,
    isLoading: false,
    isError,
    isSubmitting,
  };
};

export const useAgentUnlockAccount = (): UnlockAccountResponse<
  PostUnlockAccountRequest,
  ResponseObject<ServicingResponse<UnlockAccountSuccess>, UnlockAccountError>
> => {
  const {
    mutateAsync,
    isPending: isSubmitting,
    isError,
  } = useMutation({
    mutationFn: async (props: PostUnlockAccountRequest) => {
      const { userId, partnerId } = props;

      let unlockAccountError: UnlockAccountError | undefined;
      let success: ServicingResponse<UnlockAccountSuccess> | undefined;
      if (!!userId && !!partnerId) {
        await postUnlockAccount(userId, partnerId)
          .then((res) => (success = res))
          .catch(() => {
            unlockAccountError = 'Unknown error';
            success = undefined;
          });
      }
      const response: ResponseObject<
        ServicingResponse<UnlockAccountSuccess>,
        UnlockAccountError
      > = {
        success,
        error: unlockAccountError,
      };

      return response;
    },
  });

  return {
    unlockAccount: mutateAsync,
    isLoading: false,
    isError,
    isSubmitting,
  };
};

export const useAccountStatus = (request: AccountStatusRequest): GetAccountStatusResponse => {
  const { userId, partnerId } = request;
  const { data, isError, isLoading, refetch } = useQuery({
    queryKey: [QUERY_KEYS.AGENT_SUPPORT_ACCOUNT_STATUS, userId, partnerId],
    queryFn: async () => {
      let accountStatus: AccountStatusType | undefined;
      if (!!userId && !!partnerId) {
        const expectedStatuses = [403, 404] as const;
        await postAccountStatus(userId, partnerId, {
          dontLogErrorsForStatus: expectedStatuses,
        })
          .then((res) => {
            if (res.payload.accountActive) {
              accountStatus = 'Active';
            } else if (res.payload.errorCode === 401011) {
              accountStatus = 'Locked';
            } else if (res.payload.errorCode === 401013) {
              accountStatus = 'Suspended';
            } else if (res.payload.errorCode === 401014) {
              accountStatus = 'Hold';
            }
          })
          .catch((error: ServicingRequestError) => {
            const errorStatusCode = error.response?.status as (typeof expectedStatuses)[number];
            if (errorStatusCode === 403 || errorStatusCode === 404) {
              return;
            } else {
              throw error;
            }
          });
      }

      return accountStatus ?? 'Inactive';
    },
  });

  return {
    accountStatus: data,
    isLoading,
    isError,
    refetch,
  };
};

export const useUpdateEmail = (): UpdateEmailResponse<
  UpdateEmailRequest,
  ResponseObject<ServicingResponse<UpdateEmailSuccess>, UpdateEmailError>
> => {
  const {
    mutateAsync,
    isPending: isSubmitting,
    isError,
  } = useMutation({
    mutationFn: async (request: UpdateEmailRequest) => {
      if (!request.userId) {
        throw new Error('userId is undefined, cannot make a request without userId');
      }
      let successResponse: ServicingResponse<UpdateEmailSuccess> | undefined;
      let updateEmailError: UpdateEmailError | undefined;

      const expectedStatuses = [400] as const;
      await updateEmail(request, { dontLogErrorsForStatus: expectedStatuses })
        .then((res) => (successResponse = res))
        .catch((error: ServicingRequestError) => {
          const errorMessageCode = error.errorStack?.status.messages?.[0]?.code as ErrorCode;
          if (errorMessageCode === 400023) updateEmailError = 'Invalid Request';
          else {
            updateEmailError = 'unknown';
          }
          successResponse = undefined;
        });

      const response: ResponseObject<ServicingResponse<UpdateEmailSuccess>, UpdateEmailError> = {
        success: successResponse,
        error: updateEmailError,
      };

      return response;
    },
  });

  return {
    updateEmail: mutateAsync,
    isLoading: false,
    isError,
    isSubmitting,
  };
};
