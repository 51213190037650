import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'CustomerSearchForm' })((theme) => ({
  root: {},
  input: {
    minWidth: 360,
    '& .MuiInputLabel-formControl': {
      color: theme.palette.common.white,
    },
    '& .MuiFormHelperText-root.Mui-error': {
      '&:before': {
        backgroundColor: theme.palette.common.white,
      },
      color: theme.palette.common.white,
    },
  },
  midSection: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingTop: 35,
  },
  or: {
    color: theme.palette.common.white,
    paddingTop: 36,
  },
  searchButton: {
    marginTop: 22,
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.main,
    borderRadius: 25,
    padding: `${theme.spacing(2)} ${theme.spacing(3.75)}`,
    '&:hover': {
      backgroundColor: theme.palette.grey[300],
    },
  },
}));
