import type { AccordionSummaryProps } from '@mui/material';
import { AccordionSummary as MuiAccordionSummary } from '@mui/material';

import { IconUIExpandMore } from '@ecp/themes/base';

import { useStyles } from './CustomerSearchAccordionSummary.styles';

export const CustomerSearchAccordionSummary: React.FC<AccordionSummaryProps> = ({ ...props }) => {
  const { classes } = useStyles();

  return (
    <MuiAccordionSummary
      expandIcon={<IconUIExpandMore />}
      classes={{ root: classes.root, expandIconWrapper: classes.iconWrapper }}
      {...props}
    />
  );
};
