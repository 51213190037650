import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'PoliciesSearchResults' })((theme) => ({
  root: {
    border: `1px solid ${theme.palette.other.border}`,
  },
  address: theme.typography.body2,
  welcomeText: {
    textAlign: 'center',
  },
  tableHeadCell: {
    ...theme.typography.body1Bold,
  },
  tableRow: {
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.grey[100],
    },
  },
  tableCell: {
    borderBottom: 'none',
    maxWidth: 134,
    overflowWrap: 'break-word',
  },
  link: theme.typography.body2,
}));
