import type {
  AuthenticationResult,
  IPublicClientApplication,
  SilentRequest,
} from '@azure/msal-browser';
import { InteractionRequiredAuthError } from '@azure/msal-browser';

import { datadogLog } from '@ecp/utils/logger';

export interface AuthUtil {
  authResult: Promise<AuthenticationResult | void>;
  isAuth: boolean;
}

/**
 * Azure authentication utility.
 */
export class AzureAuthUtil implements AuthUtil {
  constructor(
    /**
     * Azure context.
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    private _instance: IPublicClientApplication,
    /**
     * Login request.
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    private _loginRequest: SilentRequest,
  ) {}

  get authResult(): Promise<AuthenticationResult | void> {
    const accounts = this._instance.getAllAccounts();
    const activeAccount = this._instance.getActiveAccount();

    // If there's no active account available, user hasn't signed in yet, redirect to Azure login page
    if (!accounts[0])
      return this._instance.acquireTokenRedirect(this._loginRequest).catch((error) => {
        datadogLog({
          logType: 'warn',
          message: `Agent - MSAL - failed to acquireTokenRedirect - errorCode=${error.errorCode}. ${error.message}`,
          context: {
            logOrigin: 'libs/utils/auth/src/azureAuth/util.ts',
            functionOrigin: 'authResult',
          },
          error,
        });
      });

    const request = {
      ...this._loginRequest,
      account: activeAccount || accounts[0],
    };

    return this._instance.acquireTokenSilent(request).catch((error) => {
      if (error instanceof InteractionRequiredAuthError) {
        datadogLog({
          logType: 'warn',
          message: `Agent - MSAL - failed to acquireTokenSilent - errorCode=${error.errorCode}. This is likely just that the token expired - ${error.message}`,
          context: {
            logOrigin: 'libs/utils/auth/src/azureAuth/util.ts',
            functionOrigin: 'authResult',
          },
          error,
        });

        // Acquire token silent failure, send an interactive request
        this._instance.acquireTokenRedirect(this._loginRequest);
      }

      datadogLog({
        logType: 'error',
        message: `Agent - MSAL - failed to acquireToken - ${error?.message}`,
        context: {
          logOrigin: 'libs/utils/auth/src/azureAuth/util.ts',
          functionOrigin: 'authResult',
        },
        error,
      });
    });
  }

  get isAuth(): boolean {
    const accounts = this._instance.getAllAccounts();

    return accounts.length > 0;
  }
}
