import { makeStyles } from '@ecp/themes/base';

// Define the styles using the makeStyles function
export const useStyles = makeStyles({ name: 'AssociatedPoliciesCardHeader' })((theme) => ({
  centerOnIcon: {
    height: 47,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));
