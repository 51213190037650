import { initializeAzureAuth } from '@ecp/utils/auth';
import * as flags from '@ecp/utils/flags';
import { datadogLog, datadogLogs, datadogRum, setCustomActionName } from '@ecp/utils/logger';
import { sessionStorage } from '@ecp/utils/storage';

import { env } from '@ecp/env';
import { loginRequest, msalInstance } from '@ecp/features/servicing/shared/azure-auth';

const initializeLogger = (): void => {
  // Datadog team requested their environment be called prod. So we update that here.
  const dataDogEnvironment = env.runtimeEnv === 'production' ? 'prod' : env.runtimeEnv;

  const datadogCommonConfig: Parameters<typeof datadogLogs.init>[0] &
    Parameters<typeof datadogRum.init>[0] = {
    applicationId: env.datadogApplicationId,
    clientToken: env.datadogClientToken,
    site: 'datadoghq.com',
    service: env.datadogService,
    env: dataDogEnvironment,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    version: env.static.releaseVersion,
    /** Mask all text on the screen */
    defaultPrivacyLevel: 'mask',
  } as const;

  datadogLogs.init({
    ...datadogCommonConfig,
    // forwardErrorsToLogs defaults to true. This will automatically capture
    // all uncaught exceptions. If an uncaught exception contains PII/PCI data
    // it will not go through the scrubbing methods so we can't enable it
    forwardErrorsToLogs: false,
  });

  datadogRum.init({
    ...datadogCommonConfig,
    trackUserInteractions: true,
    allowedTracingUrls: env.datadogAllowedTracingOrigins,
    beforeSend: setCustomActionName,
    trackResources: true,
    trackLongTasks: true,
  });

  if (env.datadogSessionReplayEnabled) datadogRum.startSessionReplayRecording();
};

const initializeOptimizely = async (): Promise<void> => {
  const handleError = (reason?: string, error?: Error): void => {
    datadogLog({
      logType: 'error',
      message: reason
        ? `Optimizely SDK failed with ${reason} - ${error?.message}`
        : `Optimizely SDK failed with non ok result - ${error?.message}`,
      context: {
        logOrigin: 'apps/servicing/agent/src/initialize.ts',
        functionOrigin: 'initializeOptimizely',
      },
      error,
    });
  };

  await flags
    .initialize({
      sdkKey: env.optimizely.sdkKey,
      storage: sessionStorage,
      logLevel: 'WARNING',
    })
    .then((result) => {
      if (!result?.success) handleError(result?.reason);
    })
    .catch((error) => {
      handleError('Optimizely SDK failed with unknown error', error);
    });

  flags.updateUserAttributes({
    baseExp: env.static.baseExp,
    partnerId: env.static.partnerId,
    partnerName: env.static.partnerName,
    opcoName: env.static.opcoName,
    testAudience: env.optimizely.testAudience,
  });
};

export const initializeGlobal = async (): Promise<void> => {
  // eslint-disable-next-line no-console
  console.log(
    `Platform Build: ${env.static.releaseVersion} - ${new Date(
      env.static.releaseTimestamp,
    ).toLocaleString()}`,
  );

  initializeLogger();

  if (env.mockapi) {
    const { registerMockServer } = await import('@ecp/utils/mock/browser');
    await registerMockServer();
  }

  if (env.optimizely.enabled) await initializeOptimizely();

  await initializeAzureAuth({ msalInstance, loginRequest });
};
