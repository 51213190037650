import type { PolicyStatus } from '@ecp/features/servicing/shared/types';

type DisplayedPolicyStatus = 'ACTIVE' | 'CANCELED' | 'PENDING' | undefined;

export const getDisplayedPolicyStatus = (status: PolicyStatus): DisplayedPolicyStatus => {
  let displayedPolicyStatus: DisplayedPolicyStatus;

  switch (status) {
    case 'In Force':
    case 'Expired':
      displayedPolicyStatus = 'ACTIVE';
      break;
    case 'Canceled':
      displayedPolicyStatus = 'CANCELED';
      break;
    case 'Scheduled':
      displayedPolicyStatus = 'PENDING';
  }

  return displayedPolicyStatus;
};
