import {
  Box,
  Table as MuiTable,
  Stack,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';

import { makeUrl } from '@ecp/utils/network';

import { AddressDisplay, PolicyIcon } from '@ecp/features/servicing/shared/components';
import { QUERY_PARAMS } from '@ecp/features/servicing/shared/routing';
import type { UiPolicyUserSearchResult } from '@ecp/features/servicing/shared/state';
import { getPersonByRefId, getPolicyAddress } from '@ecp/features/servicing/shared/state';
import { encodeUrlValue } from '@ecp/features/servicing/shared/util';

import { useStyles } from './UserPoliciesSearchResults.styles';
interface UserPoliciesSearchResultsProps {
  results: UiPolicyUserSearchResult[];
}
const tableDataHeaders = [
  'User ID',
  'Email address',
  'Customer name',
  'Policy',
  'Address Garaged/Insured',
  'Partner',
  '',
];

export const UserPoliciesSearchResults: React.FC<UserPoliciesSearchResultsProps> = (props) => {
  const { results } = props;
  const { classes, cx } = useStyles();
  const [searchParams] = useSearchParams();
  const searchFor = searchParams.get(QUERY_PARAMS.SEARCH_FOR);
  const type = searchParams.get(QUERY_PARAMS.TYPE);

  return (
    <div className={classes.root}>
      {!results ? (
        <Box py={4}>
          <h2 className={classes.welcomeText}>Welcome back</h2>
          <p className={classes.welcomeText}>Please enter search criteria to get started.</p>
        </Box>
      ) : (
        <MuiTable>
          <TableHead>
            <TableRow>
              {tableDataHeaders.map((header) => (
                <TableCell key={header} className={classes.tableHeadCell}>
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {results.map((result, userIndex) =>
              result.policies.map((policyData, policyIndex) => {
                const pni = getPersonByRefId(policyData, policyData.policy.primaryInsuredRefId);
                const address = getPolicyAddress(policyData);

                return (
                  <TableRow
                    key={policyData.policy.policyNumber}
                    className={cx(classes.tableRow, userIndex % 2 === 1 && classes.tableRowOddUser)}
                  >
                    <TableCell className={classes.tableCell}>
                      {policyIndex === 0 ? result.loginId : ''}
                    </TableCell>
                    <TableCell className={classes.tableCell}>{pni?.email}</TableCell>
                    <TableCell
                      className={classes.tableCell}
                    >{`${pni?.firstName} ${pni?.lastName}`}</TableCell>
                    <TableCell className={classes.tableCell}>
                      <Stack spacing={1} direction='row' alignItems='center'>
                        <PolicyIcon
                          productLine={policyData.coverageOptions[0].productLine}
                          policyTypeCode={policyData.coverageOptions[0].policyTypeCode}
                          classes={{ icon: classes.iconContainer }}
                        />
                        <p>{policyData.policy.policyNumber}</p>
                      </Stack>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <AddressDisplay policyAddress={address} classes={{ text: classes.address }} />
                    </TableCell>
                    <TableCell className={cx(classes.tableCell, classes.partnerIdCell)}>
                      {policyData.partner?.partnerId}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {policyIndex === 0 && searchFor && type && (
                        <Link
                          to={makeUrl({
                            url: '/account-overview',
                            params: {
                              [QUERY_PARAMS.POLICY_NUMBER]: encodeUrlValue(
                                policyData.policy.policyNumber,
                              ),
                              [QUERY_PARAMS.SEARCH_FOR]: searchFor,
                              [QUERY_PARAMS.TYPE]: type,
                              ...(result.loginId
                                ? { [QUERY_PARAMS.OKTA_LOGIN]: encodeUrlValue(result.loginId) }
                                : {}),
                            },
                            withCacheBuster: false,
                          })}
                          className={classes.link}
                        >
                          View
                        </Link>
                      )}
                    </TableCell>
                  </TableRow>
                );
              }),
            )}
          </TableBody>
        </MuiTable>
      )}
    </div>
  );
};
