import { Box, Paper } from '@mui/material';

import { useStyles } from './AssociatedPoliciesCard.styles';

interface AssociatedPoliciesCardProps {
  children: React.ReactNode;
}

export const AssociatedPoliciesCard: React.FC<AssociatedPoliciesCardProps> = ({ children }) => {
  const { classes } = useStyles();

  return (
    <Paper variant='outlined' className={classes.paper}>
      <Box className={classes.root}>{children}</Box>
    </Paper>
  );
};
