import type { CSSObject, Theme } from '@ecp/themes/base';
import { makeStyles } from '@ecp/themes/base';

const getLeftRightPadding = (theme: Theme): CSSObject => ({
  '&:first-child': {
    paddingLeft: theme.spacing(3),
  },
  '&:last-child': {
    paddingRight: theme.spacing(3),
  },
});

export const useStyles = makeStyles({ name: 'UserPoliciesSearchResults' })((theme) => ({
  root: {},
  address: theme.typography.body2,
  welcomeText: {
    textAlign: 'center',
  },
  tableHeadCell: {
    ...theme.typography.body1Bold,
    ...getLeftRightPadding(theme),
    padding: 12,
  },
  tableRow: {},
  tableRowOddUser: {
    backgroundColor: theme.palette.grey[100],
  },
  tableCell: {
    ...theme.typography.body2,
    ...getLeftRightPadding(theme),
    borderBottom: 'none',
    maxWidth: 190,
    overflowWrap: 'break-word',
    padding: 12,
    verticalAlign: 'top',
  },
  partnerIdCell: {
    width: 70,
  },
  link: theme.typography.body2,
  iconContainer: {
    height: 24,
    width: 'auto',
  },
}));
