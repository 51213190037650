import { Box, Container } from '@mui/material';

import { flagValues, flagVariables } from '@ecp/utils/flags';

import { Alert } from '@ecp/components';

import { AgentFooter } from '../AgentFooter';
import { AgentHeader } from '../AgentHeader';
import { useStyles } from './AgentPageLayout.styles';

interface AgentPageLayoutAuthenticatedProps {
  children: React.ReactElement;
}

export const AgentPageLayoutAuthenticated: React.FC<AgentPageLayoutAuthenticatedProps> = ({
  children,
}) => {
  // Use the styles hook to apply styles
  const { classes } = useStyles();

  const outageNotice = flagValues.OUTAGE_NOTICE;
  const outageText = outageNotice ? flagVariables.OUTAGE_NOTICE.OUTAGE_TEXT : '';

  return (
    <Box className={classes.root} data-testid='agent-page-layout-authenticated'>
      <AgentHeader />
      <Box component='main' className={classes.main}>
        <Container>
          {outageNotice && (
            <Alert withIcon type='warning' className={classes.maintenanceAlert}>
              {outageText}
            </Alert>
          )}
          <Box className={classes.content}>{children}</Box>
        </Container>
      </Box>
      <AgentFooter />
    </Box>
  );
};
