import * as yup from 'yup';

import type { YupSchemaMap } from '@ecp/utils/form';

import { getBasePolicyNumberSchema } from '@ecp/features/servicing/shared/components';

export interface LinkPolicyToAccountInput {
  policyNumber?: string;
}

export const linkPolicyToAccountSchema: YupSchemaMap<LinkPolicyToAccountInput> = {
  policyNumber: yup.string().concat(getBasePolicyNumberSchema()),
};
