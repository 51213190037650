import type { PropsWithChildren } from 'react';

import { AccordionDetails as MuiAccordionDetails } from '@mui/material';

import { useStyles } from './CustomerSearchAccordionDetails.styles';

export const CustomerSearchAccordionDetails: React.FC<PropsWithChildren> = ({ ...props }) => {
  const { classes } = useStyles();

  return <MuiAccordionDetails {...props} className={classes.root} />;
};
