import { memo, useCallback, useState } from 'react';

import {
  Box,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import { Link } from '@ecp/components';
import { AddressDisplay } from '@ecp/features/servicing/shared/components';
import { getPersonByRefId, getPolicyAddress } from '@ecp/features/servicing/shared/state';
import type { UiPolicy } from '@ecp/features/servicing/shared/types';

import { LinkPolicyModal } from '../LinkPolicyModal';
import { useStyles } from './PoliciesSearchResults.styles';

export interface PoliciesSearchResultsProps {
  partnerId?: string;
  policiesSearch?: UiPolicy;
  onSuccess?(value: string): void;
  onError?(value: string): void;
  userId?: string;
}
const tableDataHeaders = [
  'Email address',
  'Customer name',
  'Policy',
  'Address Garaged/Insured',
  'Partner',
  'Status',
];

const LinkStatus: React.FC<{ linkStatus?: boolean; onClick: () => void }> = memo((props) => {
  const { classes } = useStyles();
  const { linkStatus, onClick } = props;

  if (linkStatus) return <>Enrolled</>;

  return (
    <Link
      component='button'
      className={classes.link}
      onClick={onClick}
      trackingName='link_button'
      trackingLabel='link_continue'
    >
      Link
    </Link>
  );
});

export const PoliciesSearchResults: React.FC<PoliciesSearchResultsProps> = (props) => {
  const { partnerId, policiesSearch, onSuccess, onError, userId } = props;
  const { classes } = useStyles();
  const [showLinkPolicyModal, setShowLinkPolicyModal] = useState(false);

  const handleClick = useCallback(() => {
    setShowLinkPolicyModal(true);
  }, [setShowLinkPolicyModal]);

  const primaryNamedInsured = getPersonByRefId(
    policiesSearch,
    policiesSearch?.policy.primaryInsuredRefId,
  );

  const address = getPolicyAddress(policiesSearch);

  const handleSuccess = useCallback(
    (value: string) => {
      onSuccess?.(value);
    },
    [onSuccess],
  );

  const handleError = useCallback(
    (value: string) => {
      onError?.(value);
    },
    [onError],
  );

  const handleClose = useCallback(() => {
    setShowLinkPolicyModal(false);
  }, [setShowLinkPolicyModal]);

  return (
    <>
      <div className={classes.root}>
        {!policiesSearch ? (
          <Box py={4}>
            <h2 className={classes.welcomeText}>Welcome back</h2>
            <Typography className={classes.welcomeText}>
              Please enter search criteria to get started.
            </Typography>
          </Box>
        ) : (
          <MuiTable>
            <TableHead>
              <TableRow>
                {tableDataHeaders.map((header) => (
                  <TableCell key={header} className={classes.tableHeadCell}>
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow key={policiesSearch.policy.policyNumber} className={classes.tableRow}>
                {/* <TableCell className={classes.tableCell}>USERID</TableCell> */}
                <TableCell className={classes.tableCell}>{primaryNamedInsured?.email}</TableCell>
                <TableCell className={classes.tableCell}>
                  {`${primaryNamedInsured?.firstName} ${primaryNamedInsured?.lastName}`}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {policiesSearch.policy.policyNumber}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <AddressDisplay policyAddress={address} classes={{ text: classes.address }} />
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {policiesSearch.partner?.partnerId}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {policiesSearch?.partner?.partnerId === partnerId ? (
                    <LinkStatus linkStatus={policiesSearch.linkStatus} onClick={handleClick} />
                  ) : (
                    'Ineligible'
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </MuiTable>
        )}
      </div>
      <LinkPolicyModal
        partnerId={policiesSearch?.partner?.partnerId}
        policyNumber={policiesSearch?.policy.policyNumber}
        open={showLinkPolicyModal}
        onSuccess={handleSuccess}
        onError={handleError}
        onClose={handleClose}
        userId={userId}
      />
    </>
  );
};
