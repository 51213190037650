import { useCallback } from 'react';

import { Box, Stack } from '@mui/material';

import { Alert, Card } from '@ecp/components';
import type { UserSearchType } from '@ecp/features/servicing/shared/state';
import { useSharedState, useUserSearch } from '@ecp/features/servicing/shared/state';
import { useDecodedParams } from '@ecp/features/servicing/shared/util';

import { OopsMessage, UserPoliciesSearchResults, WelcomeBack } from '../../components';
import { useStyles } from './AgentLandingPageWelcome.styles';

export const AgentLandingPageWelcome: React.FC = () => {
  const { classes } = useStyles();
  const { searchFor, type } = useDecodedParams();
  const [multipleSearchError, setMultipleSearchError] =
    useSharedState<boolean>('multipleSearchError');

  const { results } = useUserSearch(searchFor, type as UserSearchType);

  const handleCloseAlert = useCallback(() => {
    setMultipleSearchError(false);
  }, [setMultipleSearchError]);

  const searchResult = results?.length ? (
    <UserPoliciesSearchResults results={results} />
  ) : (
    <OopsMessage />
  );

  const body = (
    <Box data-testid='agent-landing-page-welcome'>
      {results || multipleSearchError ? (
        searchResult
      ) : (
        // Show "Welcome Back" message by default
        <WelcomeBack variant='userSearch' />
      )}
    </Box>
  );

  const header = (
    <>
      {multipleSearchError && (
        <Alert type='error' withIcon withAction onClose={handleCloseAlert}>
          Please enter only a policy number or email address
        </Alert>
      )}
      {(results || multipleSearchError) && (
        <h1>
          {results?.length ?? 0} result{results?.length === 1 ? '' : 's'} found
        </h1>
      )}
    </>
  );

  return (
    <Stack spacing={2}>
      {header}
      <Card
        body={body}
        divider={false}
        classes={{ root: classes.root, content: classes.content }}
      />
    </Stack>
  );
};
