import { useCallback } from 'react';

import { Box, Chip, Divider, Paper, Stack } from '@mui/material';

import { Button } from '@ecp/components';
import { LoadingOverlay } from '@ecp/features/servicing/shared/components';
import { useAgentUnlockAccount } from '@ecp/features/servicing/shared/state';
import type { AccountStatusType } from '@ecp/features/servicing/shared/state';

import { useStyles } from '../AccountPolicyStatus.styles';
import { getChipColor } from '../getChipColor';

export interface AccountStatusProps {
  accountStatus: AccountStatusType;
  subTitle: string;
  enrollmentStatus?: string;
  userId?: string;
  partnerId?: string;
  onSuccess?(): void;
  onError?(value: string): void;
}

export const AccountStatus: React.FC<AccountStatusProps> = ({
  accountStatus,
  subTitle,
  enrollmentStatus,
  userId,
  partnerId,
  onSuccess,
  onError,
}) => {
  const { classes } = useStyles();
  const chipColor = getChipColor(accountStatus);
  const { isSubmitting, unlockAccount } = useAgentUnlockAccount();

  const handleUnlock = useCallback(async () => {
    if (userId) {
      const { success } = await unlockAccount({ userId, partnerId });
      if (success) {
        onSuccess?.();
      } else {
        onError?.('There was an error and the account is unable to be unlocked at this time.');
      }
    }
  }, [unlockAccount, userId, partnerId, onSuccess, onError]);

  return (
    <Paper elevation={0} variant='outlined' className={classes.accountStatusCard}>
      {isSubmitting && <LoadingOverlay />}
      <Box p={3}>
        <Stack spacing={2} divider={<Divider />}>
          <Stack spacing={1.5} direction='row' className={classes.policyStatus}>
            <Stack spacing={1.5} direction='row'>
              <p className={classes.title}>Account status</p>
              <Chip label={accountStatus?.toUpperCase()} color={chipColor} />
            </Stack>
            {accountStatus === 'Locked' && (
              <Button
                size='small'
                variant='text'
                onClick={handleUnlock}
                trackingName='unlock_account_link'
                trackingLabel='unlock_continue'
              >
                Unlock
              </Button>
            )}
          </Stack>
          <Stack spacing={2} direction='row' className={classes.stack}>
            <Stack spacing={1}>
              <p className={classes.subTitle}>{subTitle}</p>
              <p>{enrollmentStatus?.toUpperCase()}</p>
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </Paper>
  );
};
