import { BrowserRouter } from 'react-router-dom';

import { ReactMsalProvider } from '@ecp/features/servicing/shared/azure-auth';
import { useAnalyticsAgent } from '@ecp/features/servicing/shared/state';

import { RouteRenderer } from './RouteRenderer';

// need useAnalyticsAgent inside of `ReactMsalProvider` to properly trigger update when user logs in/out
const Analytics: React.FC = () => {
  useAnalyticsAgent();

  return null;
};

export const App: React.FC = () => {
  return (
    <ReactMsalProvider>
      <Analytics />
      <BrowserRouter>
        <RouteRenderer />
      </BrowserRouter>
    </ReactMsalProvider>
  );
};
