import { useCallback } from 'react';

import { useMsal } from '@azure/msal-react';

import { datadogLog } from '@ecp/utils/logger';

import { Button } from '@ecp/components';

export const SignOutButton: React.FC = () => {
  const { instance } = useMsal();

  const handleLogout = useCallback(() => {
    instance
      .logoutRedirect({
        account: instance.getAllAccounts()[0],
        postLogoutRedirectUri: '/',
      })
      .catch((e: Error) => {
        datadogLog({
          logType: 'error',
          message: `Agent - MSAL - failed to logoutRedirect. - ${e?.message}`,
          context: {
            logOrigin:
              'libs/features/servicing/shared/azure-auth/src/components/SingOutButton/SignOutButton.tsx',
            functionOrigin: 'handleLogout',
          },
          error: e,
        });
      });
  }, [instance]);

  return (
    <Button onClick={handleLogout} trackingName='sign_out_button' trackingLabel='account_continue'>
      Sign out
    </Button>
  );
};
