import type { AccordionProps } from '@mui/material';
import { Accordion as MuiAccordion } from '@mui/material';

import { useStyles } from './CustomerSearchAccordion.styles';

export const CustomerSearchAccordion: React.FC<AccordionProps> = ({ ...props }) => {
  const { classes } = useStyles();

  return <MuiAccordion className={classes.root} disableGutters elevation={0} square {...props} />;
};
