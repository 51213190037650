import { useCallback } from 'react';

import { useMsal } from '@azure/msal-react';
import { Button } from '@mui/material';

import { datadogLog } from '@ecp/utils/logger';

import { loginRequest } from '../../authConfig';

export const SignInButton: React.FC = () => {
  const { instance } = useMsal();

  const handleLogin = useCallback(() => {
    instance.loginRedirect(loginRequest).catch((e: Error) => {
      // TODO Remove console log when proper error handling is implemented
      // Will be handled under https://amfament.atlassian.net/browse/POL-3415
      console.log(e);

      datadogLog({
        logType: 'error',
        message: `Agent - MSAL - failed to login. -  ${e?.message}`,
        context: {
          logOrigin:
            'libs/features/servicing/shared/azure-auth/src/components/SignInButton/SignInButton.tsx',
          functionOrigin: 'handleLogin',
        },
        error: e,
      });
    });
  }, [instance]);

  return (
    <Button variant='contained' onClick={handleLogin}>
      Sign in
    </Button>
  );
};
