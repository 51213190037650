import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'CustomerSearchAccordionSummary' })((theme) => ({
  root: {
    padding: 0,
    '& > div.MuiAccordionSummary-content': {
      margin: '12px 0 8px',
    },
  },
  iconWrapper: {
    ...theme.typography.button2,
    color: theme.palette.secondary.contrastText,
    transform: 'none',
    '&:before': {
      content: '"Expand"',
    },
    '& svg': {
      ...theme.mixins.setColorSvg(theme.palette.primary.contrastText),
      marginTop: -2,
    },
    '&.Mui-expanded': {
      transform: 'none',
      '& svg': {
        transform: 'rotate(180deg)', // transform just the svg so the text doesn't also get transformed
      },
      '&:before': {
        content: '"Collapse"',
      },
    },
  },
}));
