import { Box, Stack } from '@mui/material';
import { Navigate, useRoutes } from 'react-router-dom';

import { flagValues } from '@ecp/utils/flags';

import { AccountOverviewPage } from '../pages/AccountOverviewPage';
import { AgentLandingPage } from '../pages/AgentLandingPage';
import { RenderedRoute } from './RenderedRoute';

const AppDisabled: React.FC = () => (
  <Box display='flex' justifyContent='center'>
    <Stack spacing={2} m={2}>
      <h1>We're Sorry</h1>
      <p>
        This website is currently unavailable. If you need immediate assistance please contact us.
      </p>
    </Stack>
  </Box>
);

export const RouteRenderer: React.FC = () => {
  const appDisabled = flagValues.DISABLE_AGENT_APP;
  const routes = useRoutes(
    appDisabled
      ? [
          {
            path: '/',
            element: <AppDisabled />,
          },
          {
            path: '*',
            element: <Navigate replace to='/' />,
          },
        ]
      : [
          {
            path: '/',
            element: <RenderedRoute component={AgentLandingPage} />,
          },
          {
            path: '/account-overview',
            element: <RenderedRoute component={AccountOverviewPage} />,
          },
          {
            path: '*',
            element: <RenderedRoute component={AgentLandingPage} />,
          },
        ],
  );

  return routes;
};
