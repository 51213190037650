import type { AccountStatusType } from '@ecp/features/servicing/shared/state';
import type { PolicyStatus } from '@ecp/features/servicing/shared/types';

type ChipColor =
  | 'success'
  | 'error'
  | 'default'
  | 'primary'
  | 'secondary'
  | 'info'
  | 'warning'
  | undefined;

export const getChipColor = (status: AccountStatusType | PolicyStatus): ChipColor => {
  let chipColor: ChipColor;

  switch (status) {
    case 'Active':
    case 'In Force':
    case 'Expired':
      chipColor = 'success';
      break;
    case 'Locked':
    case 'Canceled':
    case 'Suspended':
      chipColor = 'error';
      break;
    case 'Inactive':
    case 'Scheduled':
      chipColor = undefined;
  }

  return chipColor;
};
