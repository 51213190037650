import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    minWidth: theme.breakpoints.values.lg, // don't support small screens yet
  },
  center: {
    align: 'center',
  },
  main: {
    display: 'flex',
    flex: 1,
    backgroundColor: theme.palette.grey[200],
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  content: {
    py: 4,
  },
  maintenanceAlert: {
    marginTop: 30,
  },
}));
