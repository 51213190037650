import { Box, Typography } from '@mui/material';

import { SignInButton } from '@ecp/features/servicing/shared/azure-auth';

export const AgentPageLayoutNotAuthenticated: React.FC = () => {
  return (
    <Box display='flex' justifyContent='center' data-testid='agent-page-layout-not-authenticated'>
      <Box>
        <Box my={2}>
          <Typography align='center'>You are not authenticated. Please sign in! </Typography>
        </Box>
        <Box display='flex' justifyContent='center'>
          <SignInButton />
        </Box>
      </Box>
    </Box>
  );
};
