import { useCallback } from 'react';

import { Box, Container } from '@mui/material';

import { Button } from '@ecp/components';
import { SignOutButton } from '@ecp/features/servicing/shared/azure-auth';

export interface ResetSignOutHeaderProps {
  resetNextCallControlFunc: () => void;
}

export const ResetSignOutHeader: React.FC<ResetSignOutHeaderProps> = ({
  resetNextCallControlFunc,
}) => {
  const handleClickReset = useCallback(() => {
    resetNextCallControlFunc();
  }, [resetNextCallControlFunc]);

  return (
    <Box>
      <Container>
        <Box display='flex' justifyContent='space-between' py={3}>
          <Button
            variant='primary'
            onClick={handleClickReset}
            trackingName='reset_for_next_call_button'
            trackingLabel='reset_continue'
          >
            RESET FOR NEXT CALL
          </Button>
          <SignOutButton />
        </Box>
      </Container>
    </Box>
  );
};
