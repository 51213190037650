import { Fragment } from 'react';

import { Grid, Typography } from '@mui/material';

import { getDriverDescription, getVehicleDescription } from '@ecp/features/servicing/shared/state';
import type { UiPolicy } from '@ecp/features/servicing/shared/types';
import {
  IconMaterialDesignMapsDirectionsCar,
  IconMaterialDesignSocialPersonOutline,
} from '@ecp/themes/base';

import { useStyles } from '../AssociatedPoliciesCardContent.styles';

interface AssociatedPoliciesCardContentAutoProps {
  associatedPolicy: UiPolicy;
}

export const AssociatedPoliciesCardContentAuto: React.FC<
  AssociatedPoliciesCardContentAutoProps
> = ({ associatedPolicy }) => {
  const { vehicles, persons } = associatedPolicy;
  const { classes } = useStyles();

  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        {/* TODO: turn this into a component */}
        <Grid columnSpacing={1} className={classes.iconAndInfoContainer} container>
          <Grid item>
            <IconMaterialDesignMapsDirectionsCar />
          </Grid>
          <Grid item>
            <Grid direction='column' rowSpacing={1} container>
              {vehicles?.map((vehicle, index) => (
                <Fragment key={index}>
                  <Grid item>
                    <Typography variant='body1'>{`${getVehicleDescription(vehicle)}`}</Typography>
                  </Grid>
                </Fragment>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        {/* TODO: turn this into a component */}
        <Grid columnSpacing={1} className={classes.iconAndInfoContainer} container>
          <Grid item>
            <IconMaterialDesignSocialPersonOutline />
          </Grid>
          <Grid item>
            <Grid direction='column' rowSpacing={1} container>
              {persons.map((driver, index) => (
                <Fragment key={index}>
                  <Grid item>
                    <Typography variant='body1'>{`${getDriverDescription(driver)}`}</Typography>
                  </Grid>
                </Fragment>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
