import * as yup from 'yup';

import type { YupSchemaMap } from '@ecp/utils/form';

export interface EmailSectionFormInputs {
  email?: string;
}

export const emailSectionSchema: YupSchemaMap<EmailSectionFormInputs> = {
  email: yup.string().email('Invalid email address').required('Invalid email address'),
};
