import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'AgentLandingPageWelcome' })((theme) => ({
  root: {
    borderRadius: 0,
  },
  content: {
    padding: '0 !important',
  },
}));
