import { useCallback } from 'react';

import { Box, Grid, Typography } from '@mui/material';

import { LoadingButton } from '@ecp/features/servicing/shared/components';
import {
  getProductLineFromPolicyResponse,
  isLegacyPolicy,
  useDownloadDocument,
} from '@ecp/features/servicing/shared/state';
import type { UiPolicy } from '@ecp/features/servicing/shared/types';
import {
  IconMaterialDesignCommunicationLocationOn,
  IconMaterialDesignSocialPersonOutline,
} from '@ecp/themes/base';

import { useStyles } from '../AssociatedPoliciesCardContent.styles';

interface AssociatedPoliciesCardContentHomeProps {
  associatedPolicy: UiPolicy;
}

export const AssociatedPoliciesCardContentHome: React.FC<
  AssociatedPoliciesCardContentHomeProps
> = ({ associatedPolicy }) => {
  const {
    addresses,
    persons,
    policy: { policyNumber, sourceSystemName, primaryInsuredRefId, secondaryInsuredRefId },
    isInactive,
  } = associatedPolicy;
  const { classes } = useStyles();

  const productLine = getProductLineFromPolicyResponse(associatedPolicy);

  const { downloadDocument: downloadEOIDocument, isLoading: isLoadingEOI } = useDownloadDocument({
    policyNumber,
    isAgent: true,
  });
  const handleClickSendEOI = useCallback((): void => {
    downloadEOIDocument();
  }, [downloadEOIDocument]);
  const insuredAddress = addresses.find((address) => address.addressType?.includes('INSURED')); // Find the risk address of the property
  // Find primary and secondary insured persons
  const primaryInsuredPerson = persons.find((person) => person.personId === primaryInsuredRefId);
  const secondaryInsuredPerson = persons.find(
    (person) => person.personId === secondaryInsuredRefId,
  );

  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        {/* TODO: turn this into a component */}
        <Grid className={classes.iconAndInfoContainer} columnSpacing={1} container>
          <Grid item>
            <IconMaterialDesignCommunicationLocationOn />
          </Grid>
          <Grid item>
            <Typography variant='body1'>{`${insuredAddress?.line1}`}</Typography>
          </Grid>
        </Grid>
        {!isLegacyPolicy(productLine, sourceSystemName) && !isInactive && (
          <Box mt={1.25}>
            <LoadingButton
              variant='primary'
              fullWidth
              onClick={handleClickSendEOI}
              isProcessing={isLoadingEOI}
              trackingName='send_property_eoi_button'
              trackingLabel='send_property_eoi_continue'
            >
              Send Property EOI
            </LoadingButton>
          </Box>
        )}
      </Grid>
      <Grid item xs={12} md={6}>
        {/* TODO: turn this into a component */}
        <Grid className={classes.iconAndInfoContainer} columnSpacing={1} container>
          <Grid item>
            <IconMaterialDesignSocialPersonOutline />
          </Grid>
          <Grid direction='column' rowSpacing={1} container>
            <Grid item>
              <Typography variant='body1'>{`${primaryInsuredPerson?.firstName} ${primaryInsuredPerson?.lastName}`}</Typography>
            </Grid>
            {!!secondaryInsuredPerson && (
              <Grid item>
                <Typography variant='body1'>{`${secondaryInsuredPerson?.firstName} ${secondaryInsuredPerson?.lastName}`}</Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
