import { useEffect } from 'react';

import { Box, Stack } from '@mui/material';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';
import { useField, useFormContext } from '@ecp/utils/form';

import { TextField } from '@ecp/components';
import { LoadingButton, PolicyNumberField } from '@ecp/features/servicing/shared/components';
import { useUserSearch } from '@ecp/features/servicing/shared/state';
import type { UserSearchType } from '@ecp/features/servicing/shared/state';
import { useDecodedParams } from '@ecp/features/servicing/shared/util';

import { useStyles } from './CustomerSearchForm.styles';

export const CustomerSearchForm: React.FC = () => {
  const { classes } = useStyles();
  const { searchFor, type } = useDecodedParams();
  const searchType = type as UserSearchType;

  const { isFetching } = useUserSearch(searchFor, searchType);

  const { reset, setValue } = useFormContext();
  const policyNumberField = useField({
    name: 'policy',
    defaultValue: searchType === 'policy' ? searchFor : '',
  });
  const emailField = useField({
    name: 'email',
    defaultValue: searchType === 'email' ? searchFor : '',
  });

  useEffect(() => {
    //* * Clear field on click reset button */
    if (!searchFor && !searchType) {
      reset({ policy: '', email: '' });
    } else if (searchFor && searchType) {
      setValue(searchType, searchFor);
    }

    return () => {
      reset({ policy: '', email: '' });
    };
  }, [searchFor, searchType, setValue, reset]);

  return (
    <Stack spacing={4} direction='row' alignItems='flex-start'>
      <Box>
        <PolicyNumberField
          {...policyNumberField}
          className={classes.input}
          label='Policy Number'
          ariaLabel='Policy number'
          type='search'
          trackingName='policy_number'
          trackingLabel={GoogleAnalyticsLabels.REDACTED}
        />
      </Box>
      <Box>
        <h4 className={classes.or}>OR</h4>
      </Box>
      <Box>
        <TextField
          {...emailField}
          className={classes.input}
          label='Email Address'
          type='search'
          trackingName='email_address'
          trackingLabel={GoogleAnalyticsLabels.REDACTED}
        />
      </Box>
      <Box>
        <LoadingButton
          isProcessing={isFetching}
          className={classes.searchButton}
          color='secondary'
          type='submit'
          trackingName='customer_search_button'
          trackingLabel='customer_search_continue'
        >
          Search
        </LoadingButton>
      </Box>
    </Stack>
  );
};
