import { Box, Container, Typography } from '@mui/material';

export const AgentFooter: React.FC = () => {
  return (
    <Box>
      <Container>
        <Box py={4}>
          <Typography paragraph>
            This private system is intended for the use of authorized users only. Unauthorized
            access or use of this system may result in disciplinary action, civil and/or criminal
            penalties. By accessing and using this system, you agree to comply with all applicable
            laws, regulations, and enterprise policies.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};
