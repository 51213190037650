import { useCallback, useState } from 'react';

import { Box, Stack } from '@mui/material';

import { Alert } from '@ecp/components';
import { getPolicyDisplayType, useUserForPolicy } from '@ecp/features/servicing/shared/state';

import { AccountOverviewAccordion } from '../../../components/AccountOverviewAccordion';
import {
  AssociatedPoliciesCard,
  AssociatedPoliciesCardContent,
  AssociatedPoliciesCardHeader,
} from '../../../components/AssociatedPoliciesCard';

export interface AssociatedPoliciesProps {
  policyNumber: string;
  oktaLoginId?: string;
}

export const AssociatedPolicies: React.FC<AssociatedPoliciesProps> = ({
  policyNumber,
  oktaLoginId,
}) => {
  const { user, refetch } = useUserForPolicy({ policyNumber, oktaLoginId });
  const { userId } = user ?? {};

  const [message, setMessage] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  const handleCloseAlert = useCallback<
    NonNullable<React.ComponentProps<typeof Alert>['onClose']>
  >(() => {
    setMessage('');
  }, []);

  return (
    <AccountOverviewAccordion title='Associated policies' defaultExpanded>
      <Stack spacing={2}>
        {message && (
          <Alert
            withIcon
            withAction
            type={showSuccess ? 'warning' : 'error'}
            onClose={handleCloseAlert}
          >
            {message}
          </Alert>
        )}
        {user?.policies.map((policy) => {
          const {
            policy: { policyNumber, sourceSystemName },
            coverageOptions,
            partner,
            policyLink,
          } = policy;
          const { productLine, policyTypeCode } = coverageOptions[0];
          const policyType = getPolicyDisplayType(policy);

          return (
            <Box mb={2} key={policyNumber}>
              <AssociatedPoliciesCard>
                <AssociatedPoliciesCardHeader
                  policyType={policyType}
                  productLine={productLine}
                  sourceSystemName={sourceSystemName}
                  policyNumber={policyNumber}
                  userId={userId}
                  partnerId={partner?.partnerId}
                  setModalMessage={setMessage}
                  setShowSuccess={setShowSuccess}
                  policyLinkStatus={policyLink}
                  onSuccess={refetch}
                  policyTypeCode={policyTypeCode}
                />
                <AssociatedPoliciesCardContent
                  productLine={productLine}
                  associatedPolicy={policy}
                />
              </AssociatedPoliciesCard>
            </Box>
          );
        })}
      </Stack>
    </AccountOverviewAccordion>
  );
};
