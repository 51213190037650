import { Box, Paper, Stack } from '@mui/material';

export interface WelcomeBackProps {
  variant: 'userSearch' | 'linkPolicy';
}

export const WelcomeBack: React.FC<WelcomeBackProps> = ({ variant }) => {
  const isUserSearch = variant === 'userSearch';

  const body = isUserSearch ? (
    <p>Enter a policy number for the user account above</p>
  ) : (
    <p>Please enter search criteria to get started.</p>
  );

  return (
    <Paper variant={isUserSearch ? 'elevation' : 'outlined'}>
      <Box p={4}>
        <Stack alignItems='center' spacing={1} m={4}>
          <h2>Welcome Back</h2>
          {body}
        </Stack>
      </Box>
    </Paper>
  );
};
