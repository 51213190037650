import { useCallback, useState } from 'react';

import { Box, Typography } from '@mui/material';

import { scrollToTop } from '@ecp/utils/web';

import { Button } from '@ecp/components';
import { PolicyIcon, ServicingModal } from '@ecp/features/servicing/shared/components';
import { isLegacyPolicy, useAgentDelinkPolicy } from '@ecp/features/servicing/shared/state';
import type {
  PolicySource,
  PolicyTypeCode,
  ProductLine,
} from '@ecp/features/servicing/shared/types';

import { useStyles } from './AssociatedPoliciesCardHeader.styles';

interface AssociatedPoliciesCardHeaderProps {
  policyType?: string;
  productLine: ProductLine;
  sourceSystemName: PolicySource;
  policyNumber: string;
  policyLinkStatus?: string | undefined;
  userId?: string;
  partnerId?: string;
  policyTypeCode?: PolicyTypeCode;
  setModalMessage: (value: string) => void;
  setShowSuccess: (value: boolean) => void;
  onSuccess?: () => void;
}

export const AssociatedPoliciesCardHeader: React.FC<AssociatedPoliciesCardHeaderProps> = ({
  policyType,
  productLine,
  sourceSystemName,
  policyNumber,
  policyLinkStatus,
  userId,
  partnerId,
  policyTypeCode,
  setModalMessage,
  setShowSuccess,
  onSuccess,
}) => {
  const { classes } = useStyles();
  const [showDelinkModal, setShowDelinkModal] = useState(false);
  const buildTitle = (
    productLine: ProductLine,
    sourceSystemName: PolicySource,
    policyType?: string,
  ): string => {
    const isLegacy = isLegacyPolicy(productLine, sourceSystemName);

    return isLegacy ? `Legacy Connect ${policyType}` : policyType ?? '';
  };
  const { delinkPolicy, isSubmitting } = useAgentDelinkPolicy();
  const handleClickDelink = useCallback((): void => {
    setShowDelinkModal(true);
  }, [setShowDelinkModal]);

  const handleModalClose = useCallback<
    NonNullable<React.ComponentProps<typeof ServicingModal>['onClose']>
  >(() => {
    setShowDelinkModal(false);
  }, []);

  const handleSubmitButton = useCallback(async () => {
    if (userId && partnerId) {
      const { success } = await delinkPolicy({ policyNumber, userId, partnerId });
      if (success) {
        onSuccess?.();
        setModalMessage(`Policy ${policyNumber} has been delinked`);
        setShowSuccess(true);
      } else {
        setModalMessage(`An error has occurred. Policy ${policyNumber} is unable to be delinked`);
        setShowSuccess(false);
      }
      setShowDelinkModal(false);
      scrollToTop();
    }
  }, [delinkPolicy, setModalMessage, setShowSuccess, onSuccess, policyNumber, partnerId, userId]);
  const title = buildTitle(productLine, sourceSystemName, policyType);

  return (
    <Box display='flex' justifyContent='space-between'>
      <Box>
        <Box display='flex' justifyContent='flex-start'>
          <Box mr={2}>
            <PolicyIcon productLine={productLine} policyTypeCode={policyTypeCode} />
          </Box>
          <Box className={classes.centerOnIcon}>
            <Box mx={2}>
              <Typography variant='body4'>{title}</Typography>
            </Box>
          </Box>
          <Box className={classes.centerOnIcon}>
            <Typography variant='body3'>{policyNumber}</Typography>
          </Box>
        </Box>
      </Box>
      <Box>
        <Box display='flex' justifyContent='flex-end'>
          {policyLinkStatus === 'manual' && (
            <Button
              variant='outlineSecondary'
              size='small'
              onClick={handleClickDelink}
              trackingName='delink_button'
              trackingLabel={`${productLine}_delink_continue`}
            >
              Delink
            </Button>
          )}
        </Box>
      </Box>
      <ServicingModal
        open={showDelinkModal}
        title='Are you sure?'
        body='Are you sure you want to delink this policy from this user account?'
        onClose={handleModalClose}
        buttonText='Yes'
        onButtonClick={handleSubmitButton}
        linkButtonText='No'
        onButtonLinkClick={handleModalClose}
        isProcessing={isSubmitting}
      />
    </Box>
  );
};
