import { Box, Divider, Grid } from '@mui/material';

import type { ProductLine, UiPolicy } from '@ecp/features/servicing/shared/types';

import { PolicyStatus } from '../../AccountPolicyStatus/PolicyStatus';
import { AssociatedPoliciesCardContentAuto } from './AssociatedPoliciesCardContentAuto';
import { AssociatedPoliciesCardContentHome } from './AssociatedPoliciesCardContentHome';

interface AssociatedPoliciesCardContentProps {
  productLine: ProductLine;
  associatedPolicy: UiPolicy;
}

export const AssociatedPoliciesCardContent: React.FC<AssociatedPoliciesCardContentProps> = ({
  productLine,
  associatedPolicy,
}) => {
  return (
    <Box>
      <Box my={2}>
        <Divider />
      </Box>
      <Grid container>
        <Grid item xs={12} md={6}>
          {productLine === 'HOME' && (
            <AssociatedPoliciesCardContentHome associatedPolicy={associatedPolicy} />
          )}
          {productLine === 'AUTO' && (
            <AssociatedPoliciesCardContentAuto associatedPolicy={associatedPolicy} />
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <PolicyStatus
            title='Policy status'
            userPolicyStatus={associatedPolicy.policy.status}
            subTitle1='Paperless billing'
            subTitle2='Recurring payments'
          />
        </Grid>
      </Grid>
    </Box>
  );
};
