import { Box, Chip, Paper, Stack } from '@mui/material';

import type { PolicyStatus as PolicyStatusType } from '@ecp/features/servicing/shared/types';

import { useStyles } from '../AccountPolicyStatus.styles';
import { getChipColor } from '../getChipColor';
import { getDisplayedPolicyStatus } from '../getDisplayedPolicyStatus';

export interface PolicyStatusProps {
  title: string;
  userPolicyStatus: PolicyStatusType;
  subTitle1?: string;
  enrollmentStatus1?: string;
  subTitle2?: string;
  enrollmentStatus2?: string;
}

export const PolicyStatus: React.FC<PolicyStatusProps> = ({ title, userPolicyStatus }) => {
  const { classes } = useStyles();
  const chipColor = getChipColor(userPolicyStatus);
  const displayedPolicyStatus = getDisplayedPolicyStatus(userPolicyStatus);

  return (
    <Paper elevation={0} variant='outlined' className={classes.policyStatusCard}>
      <Box p={4}>
        <Box mb={0}>
          <Stack spacing={1.5} direction='row'>
            <p className={classes.title}>{title}</p>
            <Chip label={displayedPolicyStatus} color={chipColor} />
          </Stack>
        </Box>
      </Box>
    </Paper>
  );
};
