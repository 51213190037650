import type { Configuration, SilentRequest } from '@azure/msal-browser';

import { env } from '@ecp/env';

// Define the MSAL configuration object
export const msalConfig: Configuration = {
  auth: {
    clientId: env.azureClientId,
    authority: env.azureAuthority, // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: `${window.location.origin}/${env.azureRedirectEndpoint}`,
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Define the login request object
export const loginRequest: SilentRequest = {
  scopes: [
    'email',
    'offline_access',
    'openid',
    'profile',
    `api://${env.azureClientId}/AgentTooling`,
  ],
};
