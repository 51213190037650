import { AgentPageLayout } from '../components/AgentPageLayout';

interface RenderedRouteProps {
  component: React.ElementType;
}

export const RenderedRoute: React.FC<RenderedRouteProps> = ({ component: Component }) => (
  <AgentPageLayout>
    <Component />
  </AgentPageLayout>
);
