import { useCallback } from 'react';

import { Stack } from '@mui/material';

import { Form, useField, useForm } from '@ecp/utils/form';

import { DatePicker } from '@ecp/components';
import { ServicingModal } from '@ecp/features/servicing/shared/components';
import { useAgentLinkPolicy } from '@ecp/features/servicing/shared/state';
import type { PostLinkPolicyRequest } from '@ecp/features/servicing/shared/state';
import { getDateOfBirthSchema } from '@ecp/features/servicing/shared/util';

import { useStyles } from './LinkPolicyModal.styles';

interface LinkPolicyModalProps {
  partnerId?: string;
  policyNumber?: string;
  open: boolean;
  onSuccess?(value: string): void;
  onError?(value: string): void;
  onClose?: () => void;
  userId?: string;
}

export const LinkPolicyModal: React.FC<LinkPolicyModalProps> = (props) => {
  const { partnerId, policyNumber, open, onSuccess, onError, onClose, userId } = props;
  const { classes } = useStyles();

  const formContext = useForm({
    validations: getDateOfBirthSchema,
  });
  const { handleSubmit } = formContext;
  const birthDateField = useField({
    formContext,
    name: 'dateOfBirth',
    defaultValue: '',
  });

  const { isSubmitting, linkPolicy } = useAgentLinkPolicy();

  const handleClose = useCallback<
    NonNullable<React.ComponentProps<typeof ServicingModal>['onClose']>
  >(() => {
    onSuccess?.('');
    onError?.('');
    onClose?.();
    formContext.reset();
  }, [onSuccess, onError, onClose, formContext]);

  const onSubmit = useCallback(() => {
    handleSubmit(async (data) => {
      const { dateOfBirth } = data;
      const linkPolicyRequest: PostLinkPolicyRequest = {
        userId: userId ?? '',
        dateOfBirth: dateOfBirth ?? '',
        policyNumber: policyNumber ?? '',
        partnerId: partnerId ?? '',
      };
      const { success, error } = await linkPolicy(linkPolicyRequest);

      if (success) {
        onClose?.();
        onSuccess?.(`Policy ${policyNumber} has been successfully linked`);
      } else {
        onClose?.();
        switch (error) {
          case 'Invalid DOB':
            onError?.(
              `The date of birth for policy ${policyNumber} does not match the primary insured's date of birth`,
            );
            break;
          case 'Policy Already linked':
            onError?.(
              `Policy ${policyNumber} cannot be linked because it is already linked to an account`,
            );
            break;
          default:
            onError?.('An error occurred and the policy was unable to be linked');
        }
      }
      formContext.reset();
    })();
  }, [
    handleSubmit,
    policyNumber,
    onSuccess,
    onError,
    linkPolicy,
    onClose,
    formContext,
    userId,
    partnerId,
  ]);

  const body = (
    <Form
      formProviderProps={formContext}
      className={classes.root}
      onSubmit={onSubmit}
      name='linkPolicyForm'
    >
      <Stack spacing={1.5}>
        <ol>
          <li>
            Follow operating procedures to verify the requestor is authorized to link this policy.
          </li>
          <li>
            Verbally request the Date of Birth from the requestor that matches the policy to be
            linked.{' '}
          </li>
          <li>Never offer or give PII information to ANY persons.</li>
        </ol>

        <DatePicker
          {...birthDateField}
          label='Date of Birth'
          fullWidth
          hidePicker
          placeholder='MM/DD/YYYY'
        />
      </Stack>
    </Form>
  );

  return (
    <ServicingModal
      size='small'
      buttonText='LINK POLICY'
      onButtonClick={onSubmit}
      open={open}
      onClose={handleClose}
      linkButtonText='Cancel'
      onButtonLinkClick={handleClose}
      body={body}
      title='Link policy to account'
      isProcessing={isSubmitting}
    />
  );
};
