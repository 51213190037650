import { makeStyles } from '@ecp/themes/base';

// Define the styles using the makeStyles function
export const useStyles = makeStyles()((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    padding: `${theme.spacing(1.5)} 0 ${theme.spacing(3)} 0`,
  },
  whiteText: {
    color: theme.palette.common.white,
  },
}));
