import { useIsAuthenticated } from '@azure/msal-react';

// Agent Components
import { AgentPageLayoutAuthenticated } from './AgentPageLayoutAuthenticated';
import { AgentPageLayoutNotAuthenticated } from './AgentPageLayoutNotAuthenticated';

interface AgentPageLayoutProps {
  children: JSX.Element;
}

export const AgentPageLayout: React.FC<AgentPageLayoutProps> = ({ children }) => {
  const isAuthenticated = useIsAuthenticated();

  return isAuthenticated ? (
    <AgentPageLayoutAuthenticated>{children}</AgentPageLayoutAuthenticated>
  ) : (
    <AgentPageLayoutNotAuthenticated />
  );
};
