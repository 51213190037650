import { Box } from '@mui/material';

import { AgentLandingPageWelcome } from './AgentLandingPageWelcome';

export const AgentLandingPage: React.FC = () => {
  return (
    <div data-testid='agent-landing-page'>
      <Box py={4}>
        <AgentLandingPageWelcome />
      </Box>
    </div>
  );
};
