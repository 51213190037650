import { makeStyles } from '@ecp/themes/base';

// Define the styles using the makeStyles function
export const useStyles = makeStyles({ name: 'AssociatedPoliciesCard' })((theme) => ({
  paper: {
    border: `1px solid ${theme.palette.grey[700]}`,
  },
  root: {
    paddingTop: theme.spacing(2.875),
    paddingRight: theme.spacing(4),
    paddingBottom: theme.spacing(2.875),
    paddingLeft: theme.spacing(4),
  },
}));
