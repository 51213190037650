import { useCallback, useState } from 'react';

import { Box, Container, Typography } from '@mui/material';

import { useStyles } from './CustomerSearch.styles';
import { CustomerSearchAccordion } from './CustomerSearchAccordion';
import { CustomerSearchAccordionDetails } from './CustomerSearchAccordionDetails';
import { CustomerSearchAccordionSummary } from './CustomerSearchAccordionSummary';
import { CustomerSearchForm } from './CustomerSearchForm';

export const CustomerSearch: React.FC = () => {
  // Use the styles hook to apply styles
  const { classes } = useStyles();
  const [expanded, setExpanded] = useState<boolean>(true);

  const handleChange = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded]);

  return (
    <Box className={classes.root}>
      <Container>
        <CustomerSearchAccordion expanded={expanded} onChange={handleChange}>
          <CustomerSearchAccordionSummary
            aria-controls='customersearch-content'
            id='customersearch-header'
            data-testid='customersearch-header'
          >
            <Typography variant='h3' className={classes.whiteText}>
              Customer Search
            </Typography>
          </CustomerSearchAccordionSummary>
          <CustomerSearchAccordionDetails>
            <CustomerSearchForm />
          </CustomerSearchAccordionDetails>
        </CustomerSearchAccordion>
      </Container>
    </Box>
  );
};
