import * as yup from 'yup';

import type { YupSchemaMap } from '@ecp/utils/form';

import { getBasePolicyNumberSchema } from '@ecp/features/servicing/shared/components';

export interface CustomerSearchFormInput {
  policy?: string;
  email?: string;
}

export const customerSearchFormSchema: YupSchemaMap<CustomerSearchFormInput> = {
  policy: yup
    .string()
    .concat(getBasePolicyNumberSchema())
    .test('required', 'Policy number is required', function (value) {
      const { email } = this.parent;
      if (!email) return !!value;

      return true;
    }),
  email: yup
    .string()
    .email()
    .test('required', 'Email address is required', function (value) {
      const { policy } = this.parent;
      if (!policy) return value !== '';

      return true;
    }),
};
