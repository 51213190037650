import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'AccountPolicyStatus' })((theme) => ({
  accountStatusCard: {
    minWidth: 380,
    minHeight: 215,
    height: '100%',
  },
  policyStatusCard: {
    minWidth: 380,
  },
  policyStatus: { justifyContent: 'space-between' },
  title: { ...theme.typography.body3Bold, alignSelf: 'center' },
  stack: { justifyContent: 'space-between' },
  subTitle: theme.typography.body1Bold,
}));
