import type { ProductLine } from '@ecp/features/servicing/shared/types';

export const getPolicyTypeByNumber = (
  policyNumber?: string,
): Extract<ProductLine, 'AUTO' | 'HOME'> | 'INVALID' => {
  if (policyNumber) {
    const legacyAutoPattern = /^(AI|AX|AB|BX)\d{8}$/i;
    const legacyHomePattern = /^(HI|HX|BH)\d{8}$/i;
    const trimmedPolicyNumber = policyNumber.replace(/[^a-zA-Z0-9]/g, '');

    //* *  legacy auto and home the same for all environments */
    if (legacyAutoPattern.test(trimmedPolicyNumber)) return 'AUTO';
    if (legacyHomePattern.test(trimmedPolicyNumber)) return 'HOME';

    if (/^(3|4|7)[a-z0-9]{7}$/i.test(trimmedPolicyNumber)) return 'HOME';
  }

  return 'INVALID';
};
