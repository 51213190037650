import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'AccountOverviewAccordion' })((theme) => ({
  root: {
    boxShadow: `0px ${theme.spacing(0.375)} ${theme.spacing(0.625)} rgba(0, 0, 0, 0.1);`,
    borderRadius: 4,
    padding: `${theme.spacing(3)} ${theme.spacing(4)}`,
  },
  accordionSummary: {
    margin: 0,
    minHeight: 'unset',
    padding: 0,
  },
  accordionDetail: {
    margin: 0,
    padding: 0,
  },
  accordionContent: {
    '&, &.Mui-expanded': { margin: 0 },
  },
  expandIconWrapper: {
    ...theme.typography.button2,
    color: theme.palette.primary.main,
    transform: 'none',
    '&:before': {
      content: '"Expand"',
    },
    '& svg': {
      ...theme.mixins.setColorSvg(theme.palette.primary.main),
      marginTop: -2,
    },
    '&.Mui-expanded': {
      transform: 'none',
      '& svg': {
        transform: 'rotate(180deg)', // transform just the svg so the text doesn't also get transformed
      },
      '&:before': {
        content: '"Collapse"',
      },
    },
  },
}));
