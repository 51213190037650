import {
  Box,
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Typography,
} from '@mui/material';

import { IconUIExpandMore } from '@ecp/themes/base';

import { useStyles } from './AccountOverviewAccordion.styles';

interface AccountOverviewAccordionProps {
  children: React.ReactNode;
  title: string;
  defaultExpanded?: boolean;
}

export const AccountOverviewAccordion: React.FC<AccountOverviewAccordionProps> = ({
  title,
  children,
  defaultExpanded = false,
}) => {
  const { classes } = useStyles();
  const kebabTitle = title.replace(/\s+/g, '-').toLowerCase();

  return (
    <MuiAccordion
      className={classes.root}
      disableGutters
      elevation={0}
      square
      defaultExpanded={defaultExpanded}
    >
      <MuiAccordionSummary
        aria-controls={`${kebabTitle}-panel-content`}
        id={`${kebabTitle}-panel-header`}
        expandIcon={<IconUIExpandMore />}
        classes={{
          root: classes.accordionSummary,
          content: classes.accordionContent,
          expandIconWrapper: classes.expandIconWrapper,
        }}
      >
        <Typography variant='h2'>{title}</Typography>
      </MuiAccordionSummary>
      <MuiAccordionDetails className={classes.accordionDetail}>
        <Box mt={3}>{children}</Box>
      </MuiAccordionDetails>
    </MuiAccordion>
  );
};
