import { useCallback, useEffect, useState } from 'react';

import { Box, Stack, Typography } from '@mui/material';

import type { AlertType } from '@ecp/components';
import { Alert } from '@ecp/components';
import { useAccountStatus, useUserForPolicy } from '@ecp/features/servicing/shared/state';
import { useDecodedParams } from '@ecp/features/servicing/shared/util';

import { AccountDetails } from '../../components/AccountDetails';
import { LinkPolicyToAccount } from '../../components/LinkPolicyToAccount';
import { AssociatedPolicies } from './AssociatedPolicies';

interface AlertState {
  showAlert?: boolean;
  alertMessage?: string;
  alertType?: AlertType;
}

export const AccountOverviewPage: React.FC = () => {
  const { policyNumber, oktaLoginId } = useDecodedParams();
  if (!policyNumber) {
    throw new Error('Cannot render account overview without policyNumber');
  }

  const [alertState, setAlertState] = useState<AlertState>({
    showAlert: false,
    alertMessage: '',
    alertType: undefined,
  });
  const [showEmailUpdateAlert, setEmailUpdateAlertState] = useState(false);

  const { user, clearCache } = useUserForPolicy({ policyNumber, oktaLoginId });
  const { userId } = user ?? {};
  const { accountStatus } = useAccountStatus({
    userId,
    partnerId: user?.partnerId,
  });

  const handleSuccess = useCallback(
    (value: string) => {
      if (value) {
        setAlertState({ showAlert: true, alertMessage: value, alertType: 'success' });
      }
    },
    [setAlertState],
  );

  const handleError = useCallback(
    (value: string) => {
      if (value) {
        setAlertState({ showAlert: true, alertMessage: value, alertType: 'error' });
        setEmailUpdateAlertState(false);
      }
    },
    [setAlertState, setEmailUpdateAlertState],
  );

  const handleInfo = useCallback(() => {
    setEmailUpdateAlertState(true);
    clearCache();
  }, [clearCache, setEmailUpdateAlertState]);

  const handleCloseAlert = useCallback(() => setAlertState({ showAlert: false }), []);
  const handleCloseEmailUpdateAlert = useCallback(() => setEmailUpdateAlertState(false), []);

  useEffect(() => {
    if (accountStatus === 'Locked') {
      setAlertState({
        showAlert: true,
        alertMessage: 'This account has been locked from too many sign in attempts',
        alertType: 'warning',
      });
    }
  }, [accountStatus]);

  return (
    <div id='agent-account-overview'>
      <Box py={4}>
        <Stack spacing={3}>
          <Stack spacing={5}>
            {showEmailUpdateAlert && (
              <Alert type='warning' withIcon withAction onClose={handleCloseEmailUpdateAlert}>
                Update the user's email on the policy to match the login email.
              </Alert>
            )}
            {alertState.showAlert && (
              <Alert type={alertState.alertType} withIcon withAction onClose={handleCloseAlert}>
                {alertState.alertMessage}
              </Alert>
            )}
          </Stack>
          <Box>
            <Typography variant='h1'>Account overview</Typography>
          </Box>
          <AccountDetails
            policyNumber={policyNumber}
            oktaLoginId={oktaLoginId}
            onSuccess={handleSuccess}
            onError={handleError}
            onSuccessfulEmailUpdate={handleInfo}
          />
          <AssociatedPolicies policyNumber={policyNumber} oktaLoginId={oktaLoginId} />
          {oktaLoginId && (
            <LinkPolicyToAccount
              policyNumber={policyNumber}
              oktaLoginId={oktaLoginId}
              partnerId={user?.partnerId}
            />
          )}
        </Stack>
      </Box>
    </div>
  );
};
